import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BatchCard from './Card';

const Batches = () => {
  const { allBatchesJson } = useStaticQuery(query);
  const batches = allBatchesJson.edges.map(batch => batch.node);
  return (
    <>
      <div className="container pt-5 pb-5 pt-md-7 pb-md-7">
        <div className="row justify-content-center">
          <div className="team col-12 pt-4 pb-4 text-center">
            <h1>Previous Batches</h1>
          </div>
        </div>

        <div id="batches" className="row justify-content-center">
          <div className="card-deck">
            {batches.map(batch => (
              <div key={ batch.id } className="card" style={{ textAlign: 'center' }}>
                <BatchCard
                  key={ batch.id }
                  card={ batch }
                />
              </div>
            ))}
          </div>
        </div>

      </div>
    </>
  );
};

const query = graphql`
  query {
    allBatchesJson {
      edges {
        node {
          id
          header
          link
          subheader
          startups {
            image
          }
          batchImage
          description
        }
      }
    }
  }
`;

export default Batches;
