import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
import Batches from '../../components/batches';
import CTA from '../../components/CTA';

const Index = () => (
  <Layout bodyClass="page-archive">
    <SEO title="Startups" />
    <div className="intro">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>Startups</h1>
            <p>
              Here you will find the information about all the startups that were part of the Mbanq Labs Accelerator.
            </p>
            <p className="mt-2">
              <CTA props={{
                link: 'https://mbanq.delio.online/',
                title: 'Mbanq Startups on Delio',
                background: '#F07725',
              }}
              />
            </p>
          </div>
        </div>
      </div>
    </div>
    <Batches />
  </Layout>
);

export default Index;
